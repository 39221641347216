<template>
  <v-btn v-bind="attrs" :color="computedColor">
    <template v-for="(_, slot) of $slots as VBtn['v-slots']" #[slot]>
      <slot :name="slot" />
    </template>
    <template #loader>
      <Spinner />
    </template>
  </v-btn>
</template>

<script setup lang="ts">
import type { VBtn } from 'vuetify/components';

// sfc-compiler has problem with inheriting complex types
// from libraries, therefor we need to use following ignore statement
type BtnProps = InstanceType<typeof VBtn>['$props'];
interface Props extends /* @vue-ignore */ BtnProps {}
defineOptions({
  inheritAttrs: false,
});

defineProps<Props>();
const attrs = useAttrs();
const computedColor = computed(() => {
  const color = (attrs.color as string) || 'primary';
  return attrs.loading || attrs.loading === '' ? `${color}-100` : color;
});
</script>
