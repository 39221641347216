import { cs, en } from 'vuetify/locale';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vuetify:before-create', ({ vuetifyOptions }) => {
    vuetifyOptions.locale = {
      messages: {
        'en-GB': en,
        cs,
      },
    };
  });
});
