export const Routes = {
  Resources: 'resources',
  ElementaryActivities: 'elementary-activities',
  CompositeActivities: 'composite-activities',
  Programs: 'programs',
  Players: 'players',
  Calendar: 'calendar',
  Orders: 'orders',
  User: 'user',
  Users: 'users',
  Tenant: 'tenant',
} as const;
