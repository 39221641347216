
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "en",
  "de",
  "sk",
  "ru"
]

export const localeLoaders = {
  "cs": [{ key: "../locales/cs.json", load: () => import("../locales/cs.json" /* webpackChunkName: "locale__src_locales_cs_json" */), cache: true }],
  "en": [{ key: "../locales/en-US.json", load: () => import("../locales/en-US.json" /* webpackChunkName: "locale__src_locales_en_US_json" */), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => import("../locales/de.json" /* webpackChunkName: "locale__src_locales_de_json" */), cache: true }],
  "sk": [{ key: "../locales/sk.json", load: () => import("../locales/sk.json" /* webpackChunkName: "locale__src_locales_sk_json" */), cache: true }],
  "ru": [{ key: "../locales/ru.json", load: () => import("../locales/ru.json" /* webpackChunkName: "locale__src_locales_ru_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "cs",
      "language": "cs",
      "name": "Čeština",
      "currency": "CZK",
      "currencyLabel": "Kč",
      "files": [
        "/src/locales/cs.json"
      ]
    },
    {
      "code": "en",
      "language": "en-US",
      "name": "English",
      "currency": "USD",
      "currencyLabel": "$",
      "files": [
        "/src/locales/en-US.json"
      ]
    },
    {
      "code": "de",
      "language": "de",
      "name": "Deutsch",
      "currency": "EUR",
      "currencyLabel": "€",
      "files": [
        "/src/locales/de.json"
      ]
    },
    {
      "code": "sk",
      "language": "sk",
      "name": "Slovenčina",
      "currency": "EUR",
      "currencyLabel": "€",
      "files": [
        "/src/locales/sk.json"
      ]
    },
    {
      "code": "ru",
      "language": "ru",
      "name": "Русский",
      "currency": "RUB",
      "currencyLabel": "₽",
      "files": [
        "/src/locales/ru.json"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "language": "cs",
    "name": "Čeština",
    "currency": "CZK",
    "currencyLabel": "Kč",
    "files": [
      {
        "path": "/src/locales/cs.json"
      }
    ]
  },
  {
    "code": "en",
    "language": "en-US",
    "name": "English",
    "currency": "USD",
    "currencyLabel": "$",
    "files": [
      {
        "path": "/src/locales/en-US.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "de",
    "name": "Deutsch",
    "currency": "EUR",
    "currencyLabel": "€",
    "files": [
      {
        "path": "/src/locales/de.json"
      }
    ]
  },
  {
    "code": "sk",
    "language": "sk",
    "name": "Slovenčina",
    "currency": "EUR",
    "currencyLabel": "€",
    "files": [
      {
        "path": "/src/locales/sk.json"
      }
    ]
  },
  {
    "code": "ru",
    "language": "ru",
    "name": "Русский",
    "currency": "RUB",
    "currencyLabel": "₽",
    "files": [
      {
        "path": "/src/locales/ru.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
