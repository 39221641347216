import type { RouteRecordName } from 'vue-router';
import type { PermissionRoute, UserPermissionType } from '@/types/global';
import { useUserStore } from '@/store/user';
import { permissionRoutes } from '@/constants/permissionRoutes';

export const usePermissionUtils = () => {
  const userStore = useUserStore();
  const loggedUserPermission = computed(
    () => (userStore.loggedUser?.permissions ?? []) as UserPermissionType[],
  );

  const hasPermission = (permission: UserPermissionType) =>
    loggedUserPermission.value.includes(permission);

  const hasRequiredPathPermission = (
    nextLocalePathName: RouteRecordName,
  ): boolean => {
    if (!nextLocalePathName) return false;

    const nextPathName = nextLocalePathName.toString().split('___')[0];
    const permission = getRoutePermission(nextPathName);

    if (permission === true) return permission;

    return loggedUserPermission.value.includes(permission);
  };

  const getRoutePermission = (
    routeName: string,
    routes: PermissionRoute[] = permissionRoutes,
  ): PermissionRoute['permission'] | true => {
    for (const permissionRoute of routes) {
      if (permissionRoute.name === routeName) {
        return permissionRoute.permission;
      }

      if (permissionRoute.child) {
        return getRoutePermission(routeName, permissionRoute.child);
      }
    }

    return true;
  };

  return {
    getRoutePermission,
    hasPermission,
    hasRequiredPathPermission,
  };
};
