<template>
  <div
    class="ant-spin ant-spin-spinning ma-1"
    aria-live="polite"
    aria-busy="true"
  >
    <span class="ant-spin-dot-holder text-primary">
      <span class="ant-spin-dot ant-spin-dot-spin">
        <i class="ant-spin-dot-item"></i>
        <i class="ant-spin-dot-item"></i>
        <i class="ant-spin-dot-item"></i>
        <i class="ant-spin-dot-item"></i>
      </span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { SpinnerSize } from '@/constants/spinnerSize';

type Props = {
  size?: `${keyof typeof SpinnerSize}`;
};
const props = withDefaults(defineProps<Props>(), { size: SpinnerSize.default });

const dotSize = computed(() => {
  switch (props.size) {
    case SpinnerSize.small:
      return '16px';
    case SpinnerSize.large:
      return '32px';
    case SpinnerSize.xLarge:
      return '48px';
    default:
      return '24px';
  }
});
</script>

<style lang="scss" scoped>
$transitionDuration: 0.3s;
$dotSize: v-bind(dotSize);

.ant-spin {
  box-sizing: border-box;
  width: $dotSize;
  height: $dotSize;
  color: var(--v-text-primary);
  list-style: none;
  position: absolute;
  display: none;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform $transitionDuration cubic-bezier(0.78, 0.14, 0.15, 0.86);

  &.ant-spin-spinning {
    position: relative;
    display: inline-block;
    opacity: 1;
  }

  .ant-spin-dot {
    position: relative;
    display: inline-block;
    width: $dotSize;
    height: $dotSize;
  }

  .ant-spin-dot-holder {
    display: inline-block;
    transition:
      transform $transitionDuration ease,
      opacity $transitionDuration ease;
    transform-origin: 50% 50%;
  }

  .ant-spin-dot-spin {
    transform: rotate(45deg);
    animation-name: antRotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .ant-spin-dot-item {
    position: absolute;
    display: block;
    width: calc(($dotSize - $dotSize / 16) / 2);
    height: calc(($dotSize - $dotSize / 16) / 2);
    background: currentColor;
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    animation-name: antSpinMove;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;

    &:nth-child(1) {
      top: 0;
      inset-inline-start: 0;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      top: 0;
      inset-inline-end: 0;
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      inset-inline-end: 0;
      bottom: 0;
      animation-delay: 0.8s;
    }

    &:nth-child(4) {
      bottom: 0;
      inset-inline-start: 0;
      animation-delay: 1.2s;
    }
  }
}

@keyframes antSpinMove {
  100% {
    opacity: 1;
  }
}

@keyframes antRotate {
  100% {
    transform: rotate(405deg);
  }
}
</style>
