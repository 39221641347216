<template>
  <div
    class="h-screen d-flex align-start align-md-center justify-center pa-6"
    data-cy="error"
  >
    <v-card color="error" variant="outlined" class="w-lg-50 w-100">
      <v-card-title>{{ t('common.error') }}</v-card-title>
      <v-card-subtitle>{{ title }}</v-card-subtitle>
      <v-card-text>{{ errorMessage }}</v-card-text>
      <v-card-actions class="justify-end">
        <Btn @click="clearError({ redirect: localePath('/') })">
          {{ t('common.backToHomePage') }}
        </Btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { clearError } from '#app';
import type { NuxtError } from '#app';
import { HttpStatus } from '@/constants/httpStatus';

type Props = {
  error: NuxtError;
};
const props = defineProps<Props>();
const localePath = useLocalePath();
const { t } = useI18n();

const title = ref(props.error.statusCode);
const errorMessage = computed(() => {
  const message = props.error.message;
  if (message && message !== '') {
    return message;
  }

  switch (props.error.statusCode) {
    case HttpStatus.FORBIDDEN:
      return t('error.forbidden');
    default:
      return t('error.somethingWentWrong');
  }
});
</script>
