import type { CalendarViewMode } from '@/types/calendar';

export const MIN_TIME = '05:00:00';

export const ALLOWED_HOURS = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
];

export enum CalendarView {
  Month = 'dayGridMonth',
  Week = 'timeGridWeek',
  WeekWithoutTime = 'dayGridWeek',
  Day = 'timeGridDay',
  DayWithoutTime = 'dayGridDay',
  List = 'listWeek',
}

export const CalendarViewModes: CalendarViewMode[] = [
  {
    type: 'month',
    titleKey: 'calendarViews.month',
    icon: 'i-mdi:view-dashboard',
    view: CalendarView.Month,
  },
  {
    type: 'week',
    titleKey: 'calendarViews.week',
    icon: 'i-mdi:alarm',
    view: CalendarView.Week,
  },
  {
    type: 'weekWithoutTime',
    titleKey: 'calendarViews.week',
    icon: 'i-mdi:alarm-off',
    view: CalendarView.WeekWithoutTime,
  },
  {
    type: 'day',
    titleKey: 'calendarViews.day',
    icon: 'i-mdi:alarm',
    view: CalendarView.Day,
  },
  {
    type: 'dayWithoutTime',
    titleKey: 'calendarViews.day',
    icon: 'i-mdi:alarm-off',
    view: CalendarView.DayWithoutTime,
  },
  {
    type: 'list',
    titleKey: 'calendarViews.list',
    icon: 'i-mdi:view-list',
    view: CalendarView.List,
  },
];
