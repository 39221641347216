import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { ProtectedPath } from '@/constants/protectedPath';
import { useUserStore } from '@/store/user';
import { usePermissionUtils } from '@/composables/usePermissionUtils';
import { jwtDecode } from 'jwt-decode';
import { addSeconds, isAfter } from 'date-fns';

const protectedPaths = Object.values(ProtectedPath);

const protectedFragments = [`${ProtectedPath.APP}-`, `${ProtectedPath.APP}___`];

const loginPath = '/login';
const homePath = `/${ProtectedPath.APP}`;

const getPathFragments = (path: string) => path.split('/');

const isPublicRoute = (to: RouteLocationNormalized) =>
  protectedFragments.every(
    (fragment) => !((to.name as string) ?? '').includes(fragment),
  );

export default defineNuxtRouteMiddleware((to) => {
  const { $localePath } = useNuxtApp();
  const userStore = useUserStore();
  const { hasRequiredPathPermission } = usePermissionUtils();
  const protectedFragment = protectedPaths.find((path) =>
    getPathFragments(to.path).includes(path),
  );

  const redirect = (route: RouteLocationRaw) => navigateTo($localePath(route));

  if (userStore.isUserLoggedIn) {
    const jwtDecoded = jwtDecode(userStore.token!);
    const jwtExpDate = addSeconds(new Date(0), jwtDecoded.exp!);
    if (isAfter(new Date(), jwtExpDate)) {
      userStore.logout();
      return redirect(loginPath);
    }
  }

  if (to.path === '/' || to.path === $localePath('/')) {
    return redirect(userStore.isUserLoggedIn ? homePath : loginPath);
  }

  if (isPublicRoute(to) && userStore.isUserLoggedIn) {
    return redirect(homePath);
  }

  const isAppPath = protectedFragment === ProtectedPath.APP;
  if (isAppPath && !userStore.isUserLoggedIn) {
    return redirect(`${loginPath}?redirect=${to.fullPath}`);
  }

  if (!hasRequiredPathPermission(to.name!)) {
    return redirect(homePath);
  }
});
