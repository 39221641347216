import { SnackbarType } from '@/constants/snackbarType';
import { HttpStatus } from '@/constants/httpStatus';
import { useUserStore } from '@/store/user';
import {
  CompositeActivitiesService,
  CountriesService,
  ElementaryActivitiesService,
  PlayersService,
  ProgramsService,
  ResourcesService,
} from '@/api';
import { apiKeys } from '@/constants/apiKeys';
import { ResponseStatus } from '@/constants/responseStatus';

export const useApi = () => {
  const userStore = useUserStore();
  const snackbar = useSnackbar();
  const { $i18n } = useNuxtApp();

  const apiRequest = async <P>(request: () => Promise<P>, key?: string) => {
    const asyncData = await (key
      ? useAsyncData(key, request)
      : useAsyncData(request));

    if (asyncData.error.value?.statusCode === HttpStatus.UNAUTHORIZED) {
      userStore.logout();

      snackbar.add({
        text: $i18n.t('error.authorizationTokenExpiredLoginAgain'),
        type: SnackbarType.error,
      });
    }

    const cause = asyncData.error.value?.cause;

    return {
      ...asyncData,
      detail:
        cause &&
        typeof cause === 'object' &&
        'body' in cause &&
        cause.body &&
        typeof cause.body === 'object' &&
        'detail' in cause.body
          ? (cause as { body?: { detail?: string } }).body?.detail
          : undefined,
    };
  };

  const loadResources =
    (onlyTrainers?: boolean) =>
    async (
      search: string,
      include?: string[],
      offset?: number,
      limit?: number,
    ) => {
      const { status, data } = await apiRequest(
        () =>
          ResourcesService.getResourceLookupAsync({
            search,
            include,
            offset,
            limit,
            onlyTrainers,
          }),
        onlyTrainers ? apiKeys.trainerList() : apiKeys.resourceList(),
      );

      if (status?.value === ResponseStatus.error) {
        snackbar.add({
          type: SnackbarType.error,
          text: $i18n.t('error.resourcesLoadingFailed'),
        });
      }

      return data.value ?? { items: [], totalItems: 0 };
    };

  const loadCompositeActivities = async (
    search: string,
    include?: string[],
    offset?: number,
    limit?: number,
  ) => {
    const { status, data } = await apiRequest(
      () =>
        CompositeActivitiesService.getCompositeActivityLookupAsync({
          search,
          include,
          offset,
          limit,
        }),
      apiKeys.compositeActivityList(),
    );

    if (status?.value === ResponseStatus.error) {
      snackbar.add({
        type: SnackbarType.error,
        text: $i18n.t('error.compositeActivitiesLoadingFailed'),
      });
    }

    return {
      items:
        data.value?.items?.map((item) => ({
          ...item,
          displayName: `${item.code} — ${item.name}`,
        })) || [],
      totalItems: data.value?.totalItems || 0,
    };
  };

  const loadPlayers = async (
    search: string,
    include?: string[],
    offset?: number,
    limit?: number,
  ) => {
    const { status, data } = await apiRequest(
      () =>
        PlayersService.getPlayerLookupAsync({
          search,
          include,
          offset,
          limit,
        }),
      apiKeys.playerList(),
    );

    if (status?.value === ResponseStatus.error) {
      snackbar.add({
        type: SnackbarType.error,
        text: $i18n.t('error.playersLoadingFailed'),
      });
    }

    return {
      items:
        data.value?.items?.map((item) => ({
          ...item,
          displayName: `${item.firstName} ${item.lastName}`,
        })) || [],
      totalItems: data.value?.totalItems || 0,
    };
  };

  const loadPrograms = async (
    search: string,
    include?: string[],
    offset?: number,
    limit?: number,
  ) => {
    const { status, data } = await apiRequest(
      () =>
        ProgramsService.getProgramLookupAsync({
          search,
          include,
          offset,
          limit,
        }),
      apiKeys.programList(),
    );

    if (status?.value === ResponseStatus.error) {
      snackbar.add({
        type: SnackbarType.error,
        text: $i18n.t('error.programLoadingFailed'),
      });
    }

    return data.value ?? { items: [], totalItems: 0 };
  };

  const loadElementaryActivities = async (
    search: string,
    include?: string[],
    offset?: number,
    limit?: number,
  ) => {
    const { status, data } = await apiRequest(
      () =>
        ElementaryActivitiesService.getElementaryActivityLookupAsync({
          search,
          include,
          offset,
          limit,
        }),
      apiKeys.elementaryActivityList(),
    );

    if (status?.value === ResponseStatus.error) {
      snackbar.add({
        type: SnackbarType.error,
        text: $i18n.t('error.elementaryActivitiesLoadingFailed'),
      });
    }

    return data.value ?? { items: [], totalItems: 0 };
  };

  const loadCountries = async (
    search: string,
    include?: string[],
    offset?: number,
    limit?: number,
  ) => {
    const { status, data } = await apiRequest(
      () =>
        CountriesService.getCountriesAsync({
          search,
          offset,
          limit,
        }),
      apiKeys.countryList(),
    );

    if (status?.value === ResponseStatus.error) {
      snackbar.add({
        type: SnackbarType.error,
        text: $i18n.t('error.countriesLoadingFailed'),
      });
    }

    return data.value ?? { items: [], totalItems: 0 };
  };

  return {
    apiRequest,
    loadResources,
    loadCompositeActivities,
    loadPlayers,
    loadPrograms,
    loadElementaryActivities,
    loadCountries,
  };
};
