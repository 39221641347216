import { default as indexKMRWd60lmZMeta } from "/src/pages/app/calendar/index.vue?macro=true";
import { default as indexw1uj45Toz2Meta } from "/src/pages/app/composite-activities/index.vue?macro=true";
import { default as index18u6KGUHQiMeta } from "/src/pages/app/elementary-activities/index.vue?macro=true";
import { default as indexw87VE5y2sVMeta } from "/src/pages/app/index.vue?macro=true";
import { default as indexsdnooUiVGoMeta } from "/src/pages/app/orders/index.vue?macro=true";
import { default as indexW0aFDhUW3OMeta } from "/src/pages/app/players/index.vue?macro=true";
import { default as indexaA3ewvNfuwMeta } from "/src/pages/app/programs/index.vue?macro=true";
import { default as indexaHRT71LkMpMeta } from "/src/pages/app/resources/index.vue?macro=true";
import { default as indexBEcs4DZXojMeta } from "/src/pages/app/tenant/index.vue?macro=true";
import { default as indexT1PimuGoRyMeta } from "/src/pages/app/user/index.vue?macro=true";
import { default as index8R7SYFBuzZMeta } from "/src/pages/app/users/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as loginTLBFe6DICLMeta } from "/src/pages/login.vue?macro=true";
import { default as request_45password_45resetgPfqNPzmHmMeta } from "/src/pages/request-password-reset.vue?macro=true";
import { default as reset_45passwordwGIRYTe8GpMeta } from "/src/pages/reset-password.vue?macro=true";
export default [
  {
    name: "app-calendar___cs",
    path: "/app/calendar",
    component: () => import("/src/pages/app/calendar/index.vue")
  },
  {
    name: "app-calendar___en",
    path: "/en/app/calendar",
    component: () => import("/src/pages/app/calendar/index.vue")
  },
  {
    name: "app-calendar___de",
    path: "/de/app/calendar",
    component: () => import("/src/pages/app/calendar/index.vue")
  },
  {
    name: "app-calendar___sk",
    path: "/sk/app/calendar",
    component: () => import("/src/pages/app/calendar/index.vue")
  },
  {
    name: "app-calendar___ru",
    path: "/ru/app/calendar",
    component: () => import("/src/pages/app/calendar/index.vue")
  },
  {
    name: "app-composite-activities___cs",
    path: "/app/composite-activities",
    component: () => import("/src/pages/app/composite-activities/index.vue")
  },
  {
    name: "app-composite-activities___en",
    path: "/en/app/composite-activities",
    component: () => import("/src/pages/app/composite-activities/index.vue")
  },
  {
    name: "app-composite-activities___de",
    path: "/de/app/composite-activities",
    component: () => import("/src/pages/app/composite-activities/index.vue")
  },
  {
    name: "app-composite-activities___sk",
    path: "/sk/app/composite-activities",
    component: () => import("/src/pages/app/composite-activities/index.vue")
  },
  {
    name: "app-composite-activities___ru",
    path: "/ru/app/composite-activities",
    component: () => import("/src/pages/app/composite-activities/index.vue")
  },
  {
    name: "app-elementary-activities___cs",
    path: "/app/elementary-activities",
    component: () => import("/src/pages/app/elementary-activities/index.vue")
  },
  {
    name: "app-elementary-activities___en",
    path: "/en/app/elementary-activities",
    component: () => import("/src/pages/app/elementary-activities/index.vue")
  },
  {
    name: "app-elementary-activities___de",
    path: "/de/app/elementary-activities",
    component: () => import("/src/pages/app/elementary-activities/index.vue")
  },
  {
    name: "app-elementary-activities___sk",
    path: "/sk/app/elementary-activities",
    component: () => import("/src/pages/app/elementary-activities/index.vue")
  },
  {
    name: "app-elementary-activities___ru",
    path: "/ru/app/elementary-activities",
    component: () => import("/src/pages/app/elementary-activities/index.vue")
  },
  {
    name: "app___cs",
    path: "/app",
    component: () => import("/src/pages/app/index.vue")
  },
  {
    name: "app___en",
    path: "/en/app",
    component: () => import("/src/pages/app/index.vue")
  },
  {
    name: "app___de",
    path: "/de/app",
    component: () => import("/src/pages/app/index.vue")
  },
  {
    name: "app___sk",
    path: "/sk/app",
    component: () => import("/src/pages/app/index.vue")
  },
  {
    name: "app___ru",
    path: "/ru/app",
    component: () => import("/src/pages/app/index.vue")
  },
  {
    name: "app-orders___cs",
    path: "/app/orders",
    component: () => import("/src/pages/app/orders/index.vue")
  },
  {
    name: "app-orders___en",
    path: "/en/app/orders",
    component: () => import("/src/pages/app/orders/index.vue")
  },
  {
    name: "app-orders___de",
    path: "/de/app/orders",
    component: () => import("/src/pages/app/orders/index.vue")
  },
  {
    name: "app-orders___sk",
    path: "/sk/app/orders",
    component: () => import("/src/pages/app/orders/index.vue")
  },
  {
    name: "app-orders___ru",
    path: "/ru/app/orders",
    component: () => import("/src/pages/app/orders/index.vue")
  },
  {
    name: "app-players___cs",
    path: "/app/players",
    component: () => import("/src/pages/app/players/index.vue")
  },
  {
    name: "app-players___en",
    path: "/en/app/players",
    component: () => import("/src/pages/app/players/index.vue")
  },
  {
    name: "app-players___de",
    path: "/de/app/players",
    component: () => import("/src/pages/app/players/index.vue")
  },
  {
    name: "app-players___sk",
    path: "/sk/app/players",
    component: () => import("/src/pages/app/players/index.vue")
  },
  {
    name: "app-players___ru",
    path: "/ru/app/players",
    component: () => import("/src/pages/app/players/index.vue")
  },
  {
    name: "app-programs___cs",
    path: "/app/programs",
    component: () => import("/src/pages/app/programs/index.vue")
  },
  {
    name: "app-programs___en",
    path: "/en/app/programs",
    component: () => import("/src/pages/app/programs/index.vue")
  },
  {
    name: "app-programs___de",
    path: "/de/app/programs",
    component: () => import("/src/pages/app/programs/index.vue")
  },
  {
    name: "app-programs___sk",
    path: "/sk/app/programs",
    component: () => import("/src/pages/app/programs/index.vue")
  },
  {
    name: "app-programs___ru",
    path: "/ru/app/programs",
    component: () => import("/src/pages/app/programs/index.vue")
  },
  {
    name: "app-resources___cs",
    path: "/app/resources",
    component: () => import("/src/pages/app/resources/index.vue")
  },
  {
    name: "app-resources___en",
    path: "/en/app/resources",
    component: () => import("/src/pages/app/resources/index.vue")
  },
  {
    name: "app-resources___de",
    path: "/de/app/resources",
    component: () => import("/src/pages/app/resources/index.vue")
  },
  {
    name: "app-resources___sk",
    path: "/sk/app/resources",
    component: () => import("/src/pages/app/resources/index.vue")
  },
  {
    name: "app-resources___ru",
    path: "/ru/app/resources",
    component: () => import("/src/pages/app/resources/index.vue")
  },
  {
    name: "app-tenant___cs",
    path: "/app/tenant",
    component: () => import("/src/pages/app/tenant/index.vue")
  },
  {
    name: "app-tenant___en",
    path: "/en/app/tenant",
    component: () => import("/src/pages/app/tenant/index.vue")
  },
  {
    name: "app-tenant___de",
    path: "/de/app/tenant",
    component: () => import("/src/pages/app/tenant/index.vue")
  },
  {
    name: "app-tenant___sk",
    path: "/sk/app/tenant",
    component: () => import("/src/pages/app/tenant/index.vue")
  },
  {
    name: "app-tenant___ru",
    path: "/ru/app/tenant",
    component: () => import("/src/pages/app/tenant/index.vue")
  },
  {
    name: "app-user___cs",
    path: "/app/user",
    component: () => import("/src/pages/app/user/index.vue")
  },
  {
    name: "app-user___en",
    path: "/en/app/user",
    component: () => import("/src/pages/app/user/index.vue")
  },
  {
    name: "app-user___de",
    path: "/de/app/user",
    component: () => import("/src/pages/app/user/index.vue")
  },
  {
    name: "app-user___sk",
    path: "/sk/app/user",
    component: () => import("/src/pages/app/user/index.vue")
  },
  {
    name: "app-user___ru",
    path: "/ru/app/user",
    component: () => import("/src/pages/app/user/index.vue")
  },
  {
    name: "app-users___cs",
    path: "/app/users",
    component: () => import("/src/pages/app/users/index.vue")
  },
  {
    name: "app-users___en",
    path: "/en/app/users",
    component: () => import("/src/pages/app/users/index.vue")
  },
  {
    name: "app-users___de",
    path: "/de/app/users",
    component: () => import("/src/pages/app/users/index.vue")
  },
  {
    name: "app-users___sk",
    path: "/sk/app/users",
    component: () => import("/src/pages/app/users/index.vue")
  },
  {
    name: "app-users___ru",
    path: "/ru/app/users",
    component: () => import("/src/pages/app/users/index.vue")
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "index___sk",
    path: "/sk",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "login___cs",
    path: "/login",
    meta: loginTLBFe6DICLMeta || {},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginTLBFe6DICLMeta || {},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    meta: loginTLBFe6DICLMeta || {},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "login___sk",
    path: "/sk/login",
    meta: loginTLBFe6DICLMeta || {},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "login___ru",
    path: "/ru/login",
    meta: loginTLBFe6DICLMeta || {},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "request-password-reset___cs",
    path: "/request-password-reset",
    meta: request_45password_45resetgPfqNPzmHmMeta || {},
    component: () => import("/src/pages/request-password-reset.vue")
  },
  {
    name: "request-password-reset___en",
    path: "/en/request-password-reset",
    meta: request_45password_45resetgPfqNPzmHmMeta || {},
    component: () => import("/src/pages/request-password-reset.vue")
  },
  {
    name: "request-password-reset___de",
    path: "/de/request-password-reset",
    meta: request_45password_45resetgPfqNPzmHmMeta || {},
    component: () => import("/src/pages/request-password-reset.vue")
  },
  {
    name: "request-password-reset___sk",
    path: "/sk/request-password-reset",
    meta: request_45password_45resetgPfqNPzmHmMeta || {},
    component: () => import("/src/pages/request-password-reset.vue")
  },
  {
    name: "request-password-reset___ru",
    path: "/ru/request-password-reset",
    meta: request_45password_45resetgPfqNPzmHmMeta || {},
    component: () => import("/src/pages/request-password-reset.vue")
  },
  {
    name: "reset-password___cs",
    path: "/reset-password",
    meta: reset_45passwordwGIRYTe8GpMeta || {},
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: reset_45passwordwGIRYTe8GpMeta || {},
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "reset-password___de",
    path: "/de/reset-password",
    meta: reset_45passwordwGIRYTe8GpMeta || {},
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "reset-password___sk",
    path: "/sk/reset-password",
    meta: reset_45passwordwGIRYTe8GpMeta || {},
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "reset-password___ru",
    path: "/ru/reset-password",
    meta: reset_45passwordwGIRYTe8GpMeta || {},
    component: () => import("/src/pages/reset-password.vue")
  }
]