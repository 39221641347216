export const apiKeys = {
  calendarEvent: (id: string) => `calenderEvent:${id}`,
  calendarEventList: () => 'calendarEventList',
  compositeActivity: (id: string) => `compositeActivity:${id}`,
  compositeActivityList: () => 'compositeActivityList',
  compositeActivityInitialResourceList: () =>
    'compositeActivityInitialResourceList',
  compositeActivityResourceList: () => 'compositeActivityResourceList',
  countryList: () => 'countryList',
  elementaryActivity: (id: string) => `elementaryActivity:${id}`,
  elementaryActivityList: () => 'elementaryActivityList',
  order: (id: string) => `order:${id}`,
  orderList: () => 'orderList',
  player: (id: string) => `player:${id}`,
  playerList: () => 'playerList',
  program: (id: string) => `program:${id}`,
  programList: () => 'programList',
  resource: (id: string) => `resource:${id}`,
  resourceList: () => 'resourceList',
  tenantSettings: () => 'tenantSettings',
  trainerList: () => 'trainerList',
  trainingUnitList: () => 'trainingUnitList',
  user: (id: string) => `user:${id}`,
  userList: () => 'userList',
} as const;
