export const UserPermission = {
  ResourcesView: 'RESOURCES_VIEW',
  ResourcesCreate: 'RESOURCES_CREATE',
  ResourcesUpdate: 'RESOURCES_UPDATE',
  ResourcesDelete: 'RESOURCES_DELETE',

  ElementaryActivitiesView: 'ELEMENTARY_ACTIVITIES_VIEW',
  ElementaryActivitiesCreate: 'ELEMENTARY_ACTIVITIES_CREATE',
  ElementaryActivitiesUpdate: 'ELEMENTARY_ACTIVITIES_UPDATE',
  ElementaryActivitiesDelete: 'ELEMENTARY_ACTIVITIES_DELETE',

  CompositeActivitiesView: 'COMPOSITE_ACTIVITIES_VIEW',
  CompositeActivitiesCreate: 'COMPOSITE_ACTIVITIES_CREATE',
  CompositeActivitiesUpdate: 'COMPOSITE_ACTIVITIES_UPDATE',
  CompositeActivitiesDelete: 'COMPOSITE_ACTIVITIES_DELETE',

  ProgramsView: 'PROGRAMS_VIEW',
  ProgramsCreate: 'PROGRAMS_CREATE',
  ProgramsUpdate: 'PROGRAMS_UPDATE',
  ProgramsDelete: 'PROGRAMS_DELETE',

  PlayersView: 'PLAYERS_VIEW',
  PlayersCreate: 'PLAYERS_CREATE',
  PlayersUpdate: 'PLAYERS_UPDATE',
  PlayersDelete: 'PLAYERS_DELETE',

  CalendarEventsView: 'CALENDAR_EVENTS_VIEW',
  CalendarEventsCreate: 'CALENDAR_EVENTS_CREATE',
  CalendarEventsUpdate: 'CALENDAR_EVENTS_UPDATE',
  CalendarEventsDelete: 'CALENDAR_EVENTS_DELETE',

  OrdersView: 'ORDERS_VIEW',
  OrdersCreate: 'ORDERS_CREATE',
  OrdersUpdate: 'ORDERS_UPDATE',
  OrdersDelete: 'ORDERS_DELETE',

  TenantView: 'TENANT_VIEW',
  TenantUpdate: 'TENANT_UPDATE',

  UsersView: 'USERS_VIEW',
  UsersCreate: 'USERS_CREATE',
  UsersUpdate: 'USERS_UPDATE',
  UsersDelete: 'USERS_DELETE',
} as const;
