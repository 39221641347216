import { defineStore } from 'pinia';
import {
  AuthService,
  type CurrencySettingsEnumDto,
  type DateFormatSettingsEnumDto,
  type LanguageSettingsEnumDto,
  type LoggedUserDto,
  type TimeFormatSettingsEnumDto,
  UserService,
} from '@/api';
import { OpenAPI } from '@/api/core/OpenAPI';
import { useApi } from '@/composables/useApi';
import { ResponseStatus } from '@/constants/responseStatus';
import { Defaults } from '@/constants/defaults';
import type { CalendarViewMode } from '@/types/calendar';
import { CalendarView, CalendarViewModes } from '@/constants/calendar';

export const useUserStore = defineStore(
  'user',
  () => {
    const { apiRequest } = useApi();
    const localePath = useLocalePath();
    const loggedUser = ref<LoggedUserDto | null>(null);
    const token = ref<string | null>(null);
    const calendarViewMode = ref<CalendarViewMode>({
      ...CalendarViewModes.find((mode) => mode.view === CalendarView.Week)!,
    });
    const isUserLoggedIn = computed<boolean>(() => !!loggedUser.value);
    const language = computed<string>(
      () => loggedUser.value?.language.value || Defaults.language,
    );
    const dateFormat = computed<string>(
      () => loggedUser.value?.dateFormat.value || Defaults.dateFormat,
    );
    const timeFormat = computed<string>(
      () => loggedUser.value?.timeFormat.value || Defaults.timeFormat,
    );
    const dateTimeFormat = computed<string>(
      () => `${dateFormat.value} ${timeFormat.value}`,
    );
    const currency = computed<string>(
      () => loggedUser.value?.currency.value || Defaults.currency,
    );

    const login = async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      const request = await apiRequest(() =>
        AuthService.loginAsync({
          requestBody: { email, password },
        }),
      );

      const user = request.data.value;

      const newToken = user?.token;
      if (newToken) {
        loggedUser.value = user;
        token.value = newToken;
        localStorage.setItem('token', newToken);
        OpenAPI.TOKEN = newToken;
      }

      return request;
    };

    const saveSettings = async ({
      language,
      dateFormat,
      timeFormat,
    }: {
      language: LanguageSettingsEnumDto;
      dateFormat: DateFormatSettingsEnumDto;
      timeFormat: TimeFormatSettingsEnumDto;
    }) => {
      const request = await apiRequest(() =>
        UserService.updateUserSettingsAsync({
          requestBody: {
            language: language.key!,
            dateFormat: dateFormat.key!,
            timeFormat: timeFormat.key!,
          },
        }),
      );

      if (request.status.value === ResponseStatus.success) {
        loggedUser.value!.language = language;
        loggedUser.value!.dateFormat = dateFormat;
        loggedUser.value!.timeFormat = timeFormat;
      }

      return request;
    };

    const logout = (): void => {
      loggedUser.value = null;
      token.value = null;
      navigateTo(localePath('/login'));
    };

    const setCurrency = (currency: CurrencySettingsEnumDto) => {
      loggedUser.value!.currency = currency;
    };

    const setCalendarViewMode = (viewMode: CalendarViewMode) => {
      calendarViewMode.value = viewMode;
    };

    return {
      loggedUser,
      token,
      isUserLoggedIn,
      language,
      dateFormat,
      timeFormat,
      dateTimeFormat,
      currency,
      calendarViewMode,

      login,
      logout,
      saveSettings,
      setCurrency,
      setCalendarViewMode,
    };
  },
  { persist: { storage: localStorage } },
);
