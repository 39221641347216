import { OpenAPI } from '@/api/core/OpenAPI';

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();
  OpenAPI.BASE = runtimeConfig.public.baseApiURL as string;
  const token = localStorage.getItem('token');
  if (token) {
    OpenAPI.TOKEN = token;
  }
});
